<template>
  <main class="site-main">

    <!--상단 타이틀 영역 s-->
    <div class="top-level-page-area top-level-page-area-05">
      <div class="bms-relaunch-comp-v2">
        <h1 class="page-header">VOD Contents</h1>
        <div class="feature-body">
          <p>BMS에서 주최한 각종 심포지엄 행사 및 제품 관련 교육 동영상 VOD를 제공하는 서비스 입니다.</p>
        </div>
      </div>
    </div>
    <!--상단 타이틀 영역 e-->

    <div class="container">

      <!--Breadcrumb 영역 s-->
      <div class="top-nav-container">
        <nav role="navigator">
          <ul class="breadcrumbs list-unstyled hide-sm">
            <li class="breadcrumb"><a href="#">웨비나 & VOD</a></li>
            <li class="breadcrumb">VOD Contents</li>
          </ul>
        </nav>
      </div>
      <!--Breadcrumb 영역 e-->
      <card-and-list
          @getMoreList="getMoreList"
          :isTaSelectable="true"
          @reload="selectParam"
      />
    </div>
  </main>
</template>

<script>
import CardAndList from "@/components/common/CardAndList";
import {mapActions} from "vuex";

export default {
  name: "SymposiumVODList",
  components: {CardAndList},
  async mounted() {
    await this.initBoardList({
      query: {
        ...this.selectQuery
      },
      type: 'symposium-vod'
    })
  },
  methods: {
    ...mapActions(["fetchBoardList", "initBoardList"]),
    async selectParam(query) {
      this.selectQuery = query;
      await this.initBoardList({
        query: {
          ...query
        },
        type: 'symposium-vod'
      })
    },
    async getMoreList() {
      await this.fetchBoardList({
        query: {
          page: this.$store.state.board.page + 1,
          ...this.selectQuery
        },
        type: 'symposium-vod'
      })
    }
  },
  data() {
    return {
      selectQuery:{}
    }
  },
}
</script>

<style scoped>

</style>
